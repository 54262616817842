export const lecturers = [
  {
    id: null,
    name: "All",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (10).jpg",
    status: 1,
    lang_id: 6,
  },
  {
    id: 10,
    name: "Prof. Abdur-Razzaaq Abdul Majeed Alaro (Ilorin)",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (10).jpg",
    status: 1,
    lang_id: 6,
  },
  {
    id: 11,
    name: "Dr Muhammad Ahmad Ibrahim BUK (Kano)",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (78).jpg",
    status: 1,
    lang_id: 8,
  },
  {
    id: 54,
    name: "Ustadh Isa Christian Okonkwo (Enugu)",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (21).jpg",
    status: 1,
    lang_id: 9,
  },
  {
    id: 5,
    name: "Shaykh Rasheed Buwayb (Iwo)",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (40).jpg",
    status: 1,
    lang_id: 7,
  },
  {
    id: 12,
    name: "Dr Aliyu Bashir Umar (Kano)",
    img: "https://backend.dawahnigeria.com/sites/default/files/Cover (82).jpg",
    status: 1,
    lang_id: 8,
  },
];

export const language = [
  {
    name: "All",
    id: "",
  },
];