import React from "react";
import Container from "../../components/container/Container";
import HeaderRouter from "../../components/headerRouter/HeaderRouter";
import { useDispatch, useSelector } from "react-redux";
import { SearchContext } from "../../App";
import { NavContext } from "../../components/layout/Layout";
import { useContext } from "react";
import { FaFilter } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2";
import SearchDataWidget from "./searchDataWidget/searchDataWidget";
import {
  getSearchData,
  getSearchOptions,
  getSearchRecord,
} from "../../Redux/Actions/ActionCreators";
import { SEARCH } from "../../utils/routes/constants";
import HeadMeta from "../../components/head-meta";
const SearchPage = () => {
  const { albumId, lecturerId, text, languageId, categoryId, searchType } =
    useContext(SearchContext);
  const { searchData, searchRecord } = useSelector((state) => state.search);
  const navigate = useNavigate();
  const { setRes, setisOpen } = useContext(NavContext);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSideBar = () => {
    setRes(1);
    setisOpen(true);
  };
  useEffect(() => {
    if (!text) {
      navigate("/");
    }
  }, [text]);

  function fetchData(languageId, categoryId, lecturerId, albumId) {
    if (text === "") return;
    setLoading(true);

    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/searchApi.php`;
    const apiUrl = `${baseUrl}?type=${searchType}&value=${text}`;

    axios
      .get(apiUrl)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          dispatch(getSearchData(res.data.data));
          dispatch(getSearchRecord(res.data.total));
          dispatch(getSearchOptions({}));
        } else {
          dispatch(getSearchData([]));
          dispatch(getSearchOptions({}));
          dispatch(getSearchRecord(0));
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(getSearchData([]));
        dispatch(getSearchOptions({}));
        dispatch(getSearchRecord(0));
      });
  }

  useEffect(() => {
    if (text) {
      fetchData(languageId, categoryId, lecturerId, albumId);
    }
  }, [languageId, categoryId, lecturerId, albumId, text]);

  return (
    <Container>
      <div className=" w-full h-full max-[615px]:pt-[6px] text-sm min-[615px]:text-[16px] font-thin text-black dark:text-gray-200">
        <div className="w-full fixed inset-x-0 z-[10] bg-primary-foreground p-0 max-[615px]:border-b border-zinc-700">
          <HeadMeta
            title={`Search for ${
              text || "islamic"
            } resources on Dawah Nigeria `}
          />
          <HeaderRouter title={"Search"} />
        </div>
        <div className="pt-2 pl-2 flex items-center space-x-1 max-[615px]:hidden">
          <HiOutlineArrowLongLeft
            onClick={() => {
              navigate(-1);
            }}
            className={
              pathname === SEARCH
                ? "text-[30px] text-color"
                : "text-[30px] text-gray-400"
            }
          />
          <HiOutlineArrowLongRight
            className={
              pathname === "/"
                ? "text-[30px] text-color"
                : "text-[30px] text-zinc-400"
            }
          />
          <span className="mr-1">{"Search"}</span>/ <span></span>
          {`Search for ${text}`}
        </div>
        <div className="flex text-color text-sm font-normal flex-col px-2 py-12  min-[615px]:px-6 min-[615px]:py-6 w-full">
          <div
            onClick={() => {
              handleSideBar();
            }}
            className="my-3 w-fit space-x-2 border px-2 py-1 rounded-md min-[890px]:hidden flex items-center border-border"
          >
            <FaFilter className="text-[22px]" />
            <div>Filter</div>
          </div>
          <div className="text-lg text-foreground mb-3 min-[615px]:text-xl">{`${
            searchRecord?.toLocaleString() || 0
          } result for '${text}'`}</div>
          {loading && (
            <div className="w-full h-[300px] flex items-center justify-center">
              <div className="animate-spin w-6 h-6 min-[615px]:w-8 min-[615px]:h-8 rounded-full border-r-2 border-b-2 border-zinc-400"></div>
            </div>
          )}
          {!loading && searchData.length === 0 && (
            <div className="w-full flex items-center justify-center h-[300px]">
              <h1 className="text-3xl min-[615px]:text-4xl text-color tracking-wider ">
                No search result found
              </h1>
            </div>
          )}
          {!loading &&
            searchData.length !== 0 &&
            searchData?.map(
              (
                {
                  lec_img,
                  cat_name,
                  mp3_title,
                  mp3_description,
                  mp3_duration,
                  updated_date,
                  id,
                },
                idx
              ) => {
                return (
                  <div key={idx}>
                    <SearchDataWidget
                      id={id}
                      lec_img={lec_img}
                      cat_name={cat_name}
                      date={updated_date}
                      mp3_title={mp3_title}
                      mp3_description={mp3_description}
                      mp3_duration={mp3_duration}
                    />
                  </div>
                );
              }
            )}
        </div>
      </div>
    </Container>
  );
};

export default SearchPage;
